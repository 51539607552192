exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-your-money-back-tsx": () => import("./../../../src/pages/get-your-money-back.tsx" /* webpackChunkName: "component---src-pages-get-your-money-back-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-more-complete-protection-tsx": () => import("./../../../src/pages/more-complete-protection.tsx" /* webpackChunkName: "component---src-pages-more-complete-protection-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-we-approve-more-tsx": () => import("./../../../src/pages/we-approve-more.tsx" /* webpackChunkName: "component---src-pages-we-approve-more-tsx" */)
}

